<template>
	<div :id="mainNavIsHidden ? 'main-nav-hidden-transaction-table' : 'transactions-container'">
		<table class="transactions encore-background curved-border" v-if="transactions.length > 0">
			<table class="transactions second-table-container">
			<thead>
				<tr class="header-row">
					<!-- <th>{{ languageStrings.casinoId }}</th> -->
					<th>{{ languageStrings.fromPlayerCash }}</th>
					<th>{{ languageStrings.initiationDate }}</th>
				</tr>
			</thead>
			<!-- :class="expandedRowPresent ? checkForExpandedRows(index) ? 'selected open':'not-selected' : 'selected'" -->
			<!-- :class="expandedRowPresent ? 'not-selected': 'selected'" -->
				<tbody v-for="(item, index) in transactions" :class="expandedRowPresent ? checkForExpandedRows(index) ? 'selected open':'not-selected' : 'selected'"  :key="index" @click="setOpenClass(index)" :id="`row-${index}`">
				<tr class="data-row curved-border" >
					<!-- <td>{{ item.casinoId }}</td> -->
					<td v-if="item.fromPlayerCashCOC >= 0">
						{{
							systemCurrencyTool.formatCurrency(
								item.fromPlayerCashCOC,
								systemCurrencyTool.displayType.minorOrFull
							)
						}}
					</td>
					<td v-else class="negative-value">
						{{
							systemCurrencyTool.formatCurrency(
								item.fromPlayerCashCOC,
								systemCurrencyTool.displayType.minorOrFull
							)
						}}
					</td>
					<td v-if="item.initiationDate">
						{{ new Date(item.initiationDate).toLocaleTimeString(this.localeString, dateOptions) }}
						<i class="arrow down"></i> 
					</td>
					<td v-else></td>
				</tr>
				<tr class="panel">
					<td colspan="2">
						<table class="expandable-data-table curved-border">
							<thead>
								<th class="encore-color">{{ languageStrings.status }}</th>
								<th class="encore-color">{{ languageStrings.offerExpirationDate }}</th>
								<th class="encore-color">{{ languageStrings.provider }}</th>
								<th class="encore-color">{{ languageStrings.statusAdditionalInfo }}</th>
							</thead>
							<tr class="position-expandable-data">
								<!-- <td>{{ item.status }}</td> -->
								<td class="expandable-data" v-if="item.status === 'Committed'">{{ languageStrings.committed }}</td>
								<td class="expandable-data" v-else-if="item.status === 'Cancelled'" >{{ languageStrings.cancelled }}</td>
								<td class="expandable-data" v-else-if="item.status === 'Expired'" >{{ languageStrings.expired }}</td>
								<td class="expandable-data" v-else-if="item.status === 'OfferedToUser'" >{{ languageStrings.offeredToUser }}</td>
								<td class="expandable-data" v-else-if="item.status === 'WaitingForProviderConfirmation'" >{{ languageStrings.waitingForProviderConfirmation }}</td>
								<td class="expandable-data" v-else-if="item.status === 'RefusedByProvider'" >{{ languageStrings.refusedByProvider }}</td>
								<td class="expandable-data" v-if="item.offerExpirationDate">
									{{
										new Date(item.offerExpirationDate).toLocaleTimeString(
											this.localeString,
											dateOptions
										)
									}}
								</td>
								<td v-else></td>
								<td class="expandable-data">{{ item.provider }}</td>
								<td class="expandable-data">{{ item.statusAdditionalInfo }}</td>
							</tr>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
		</table>
		<h2 v-else class="encore-color">{{ languageStrings.noTransactionsFound }}</h2>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
export default {
	name: "TransactionsTable",
	props: {
		systemCurrencyTool: Object,
		systemSettings: Object,
		transactions: Array,
		languageStrings: Object,
		mainNavIsHidden: Boolean,
	},
	data() {
		return {
			expandedRows: [],
			expandedRowPresent: false,
		};
	},
	mounted() {
		this.eventBus.on("pageChange", () => {
			this.expandedRows.forEach(index => {
				this.setOpenClass(index);
			});
			onBeforeUnmount(() => {
				this.eventbus.off("pageChange");
			});
		});
	},
	methods: {
		setOpenClass(index) {
			var currentRow = document.getElementById(`row-${index}`);
			currentRow.classList.contains("open") ?  (currentRow.classList.remove("open"), currentRow.classList.remove("selected"), currentRow.classList.add("not-selected")) :
				(currentRow.classList.add("open"), currentRow.classList.add("selected"), currentRow.classList.remove("not-selected"));
		
			this.checkIfThereAreExpandedRows();
		},

		checkForExpandedRows(index) {
			return this.expandedRows.includes(index);
		},

		 checkIfThereAreExpandedRows() {
			let foundExpandedRow = false;
			this.transactions.forEach((element,index) => {
				var currentRow = document.getElementById(`row-${index}`);
				if(currentRow?.classList.contains("open")){
					this.expandedRowPresent = true;
					foundExpandedRow = true;
					this.expandedRows.push(index);
				}
			});

			if(!foundExpandedRow){
				this.expandedRows = [];
				this.expandedRowPresent = false;

			}
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2,
h3 {
	text-align: center;
}

#transactions-container {
	display: grid;
	/* margin: auto; */
	/* width: 80%; */
	/* background-color: #d7d7d7; */
	/* border-width: 0 1px 1px 1px;
	border-style: solid;
	box-sizing: border-box; */
}

.transactions {
	position: relative;
	width: 95%;
	margin: auto;
	border-collapse: separate;
	text-align: center;
	/* background-color: #d7d7d7; */
	/* box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%); */
	border-spacing: 0 0.7em;
	max-width: 1100px;
}

.transactions tr {
	transition: background-color 0.3s;
}

tr.header-row {
	position: sticky;
	top: 0;
	background-color: #6305c9;
	color: white;
	border-bottom: 1px solid rgb(123, 123, 123);
	white-space: break-spaces;
	line-height: 38px;
	font-size: large;
}

/* .transactions tr.data-row:hover,
.transactions tr:nth-child(2n):hover {
	background-color: #222;
	color: #fff;
} */
.transactions tbody{
	background-color: white;
	color: #6305c9;
}

.data-row {
	height: 36px;
}

td::first-letter {
	text-transform: capitalize;
}

tr td:first-of-type {
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	margin-left: 10px;
}

tr td:last-of-type {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}

tr {
	padding: 10px;
	font-weight: bold;
}

.panel {
	display: none;
	/* opacity: 50%; */
	color: #6305c9;
	height: 100px;
}

.open .panel {
	display: table-row;
	/* opacity: 100%; */
	background-color: white;
}

.expandable-data-table {
	width: 100%;
	color: #6305c9;
	background-color: white;
}

.expandable-data {
	color: #6305c9;
}

.second-table-container {
	width: 96%;
}

.position-expandable-data {
	line-height: 55px;
}

#main-nav-hidden-transaction-table {
	margin: 0;
	top: 20px;
	position: relative;
}

tbody:hover {
	cursor: pointer;
}

.encore-color {
	color: #6305c9;
}

@-moz-document url-prefix() {
	tr td:first-of-type {
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		border: 0px solid;
		border-collapse: separate;
		background-color: white;
		border-spacing: 0 0.7em;
	}
	tr td:last-of-type {
  		border-top-right-radius: 15px;
  		border-bottom-right-radius: 15px;
		border: 0px solid;
		border-collapse: separate;
		background-color: white;
		border-spacing: 0 0.7em;
	}

	.transactions tbody {
		background-color: transparent !important;
	}
	
	/* .expandable-data-table-td-styling-firefox {
		background-color: black !important;
	}

	.expandable-data-table-parent-td-firefox {
		background-color: black !important;
	}	 */
}

</style>
